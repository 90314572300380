import styles from "./styles.module.css";
import {
  useEffect as UseEffect,
  useRef as UseRef,
  useState as UseState,
} from "react";
import Link from "next/link";
import {
  DateType,
  LanguageUid,
  TimelineButton,
  Language,
  NewType,
  LanguageValueType,
  FileType,
} from "../../../../libs/enums";
import {
  ConvertToDateByType,
  GetNumberOfRecordsByScreenSize,
  IsNullOrEmpty,
} from "../../../../libs/utils";
import UseTranslation from "next-translate/useTranslation";
import Image from "next/image";
import NoImage from "../../../../assets/images/news/no-image.jpg";
import { CDN_URL } from "../../../../libs/generalSettings";
import NewsNoData from "../../../no_data/news_no_data";
import { useRouter as UseRouter } from "next/router";

var isPressDown = false;
var previousTouch;

const News = (props) => {
  const router = UseRouter();

  const [items, setItems] = UseState([]);
  const [width, setWidth] = UseState();
  const [currentSize, setCurrentSize] = UseState();
  const [listWidth, setListWidth] = UseState(0);
  const [leftPercent, setLeftPercent] = UseState(0);
  const [newType, setNewType] = UseState(
    IsNullOrEmpty(router.query.newsType)
      ? NewType.PTTNewsWorthy.Value
      : router.query.newsType
  );

  const _timeLineList = UseRef();
  const _buttons = UseRef();

  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  const { newsList } = props;

  UseEffect(() => {
    setWidth(GetNumberOfRecordsByScreenSize()[1]);
    setCurrentSize(GetNumberOfRecordsByScreenSize()[2]);
    setListWidth(
      parseInt(window.getComputedStyle(_timeLineList.current).width)
    );
  }, []);

  UseEffect(() => {
    if (!IsNullOrEmpty(_timeLineList)) {
      setItems(_timeLineList.current.children);
    }
  }, [newType]);

  const onChangeNewByType = (newType) => {
    setNewType(newType);
  };

  const TimeLineByNextAndPrev = async (event) => {
    const totalLeft = Math.ceil(items.length - 1) * width - currentSize * width;

    const elementStyleLeft = Math.abs(parseInt(items[1].style["left"]));
    let calc = 0;
    const button = _buttons.current;
    let buttons;

    if (button) {
      buttons = button.children;
    }

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].style.pointerEvents = "none";
    }

    switch (event) {
      case TimelineButton.NEXT:
        calc = leftPercent -= width;
        break;
      case TimelineButton.PREV:
        calc = leftPercent += width;
        break;
      default:
        break;
    }

    setLeftPercent(calc);
    if (elementStyleLeft < totalLeft) {
      for (let i = 0; i < items.length; i++) {
        let firstElementLeft = parseInt(items[i].style["left"]);

        if (!isNaN(firstElementLeft)) {
          const _setInterval = setInterval(() => {
            switch (event) {
              case TimelineButton.NEXT:
                firstElementLeft -= 1;
                items[i].style.left = `${firstElementLeft}%`;
                break;
              case TimelineButton.PREV:
                firstElementLeft += 1;
                items[i].style.left = `${firstElementLeft}%`;
                break;
              default:
                break;
            }

            if (calc === firstElementLeft) {
              clearInterval(_setInterval);

              for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.pointerEvents = "auto";
              }
            }
          }, 0);
        }
      }
    } else {
      setLeftPercent(0);

      for (let i = 0; i < items.length; i++) {
        let firstElementLeft = parseInt(items[i].style["left"]);

        if (!isNaN(firstElementLeft)) {
          const _setInterval = setInterval(() => {
            firstElementLeft += 1;
            items[i].style.left = `${firstElementLeft}%`;

            if (firstElementLeft === 0) {
              clearInterval(_setInterval);

              for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.pointerEvents = "auto";
              }
            }
          }, 0);
        }
      }
    }
  };

  const TimeLineByMouseDown = async () => {
    isPressDown = true;
  };

  const TimeLineByMouseMove = async (event) => {
    if (!isPressDown) return;

    const movementX = event.nativeEvent.movementX;
    let elementLeft = items[1].style["left"];
    let calc = parseInt(elementLeft);

    if (elementLeft.includes("%")) calc = (calc / 100) * listWidth;

    for (let i = 0; i < items.length; i++) {
      let leftCalc = calc + movementX;
      items[i].style.left = `${leftCalc}px`;
    }
  };

  const TimeLineByTouchMove = async (event) => {
    const touch = event.nativeEvent.touches[0];

    if (previousTouch) {
      let movementX = touch.pageX - previousTouch.pageX;
      let elementLeft = items[1].style["left"];
      let calc = parseInt(elementLeft);

      if (elementLeft.includes("%")) calc = (calc / 100) * listWidth;

      for (let i = 0; i < items.length; i++) {
        let leftCalc = calc + movementX;
        items[i].style.left = `${leftCalc}px`;
      }
    }

    previousTouch = touch;
  };

  const TimeLineByMouseLeave = async () => {
    if (!isPressDown) return;

    TimeLineByMouseUp();
  };

  const TimeLineByMouseUp = async () => {
    isPressDown = false;
    const totalLeft = -Math.abs(
      (items.length - 1) * width - currentSize * width
    );

    for (let i = 0; i < items.length; i++) {
      const elementStyleLeft = Math.round(
        (parseInt(items[i].style["left"]) / listWidth) * 100
      );
      let _isInteger = elementStyleLeft / width;
      let calc = 0;

      if (elementStyleLeft > 0) {
        const _setInterval = setInterval(() => {
          elementStyleLeft -= 1;
          items[i].style.left = `${elementStyleLeft}%`;

          if (elementStyleLeft === 0) {
            clearInterval(_setInterval);
            setLeftPercent(0);
          }
        }, 0);
      }

      if (elementStyleLeft < 0) {
        if (!isNaN(elementStyleLeft)) {
          if (!Number.isInteger(_isInteger)) {
            calc = Math.round(elementStyleLeft / width) * width;

            setLeftPercent(calc);

            const _setInterval = setInterval(() => {
              if (elementStyleLeft > calc) {
                elementStyleLeft -= 1;
              } else {
                elementStyleLeft += 1;
              }

              items[i].style.left = `${elementStyleLeft}%`;

              if (elementStyleLeft === calc) {
                clearInterval(_setInterval);
              }
            }, 0);
          }
        }
      }

      if (elementStyleLeft < totalLeft) {
        let calc = elementStyleLeft;
        const _setInterval = setInterval(() => {
          calc += 1;
          items[i].style.left = `${calc}%`;

          if (calc === totalLeft) {
            clearInterval(_setInterval);
            setLeftPercent(totalLeft);
          }
        }, 0);
      }
    }
  };

  const TimeLineByTouchEnd = async (event) => {
    const touch = event.nativeEvent.touches[0];
    previousTouch = touch;
  };

  const GetNewsByTop = (type = NewType.PTTNewsWorthy.Value) => {
    const getNewsItems = (
      <ul
        key={type}
        id={`timeLineList_${type}`}
        ref={type === newType ? _timeLineList : null}
        className={`${styles.timeLine} ${
          type === newType ? styles.isActive : ""
        }`}
        onMouseDown={async (e) => {
          await TimeLineByMouseDown(e);
        }}
        onMouseMove={async (e) => {
          await TimeLineByMouseMove(e);
        }}
        onTouchMove={async (e) => {
          await TimeLineByTouchMove(e);
        }}
        onMouseLeave={async (e) => {
          await TimeLineByMouseLeave(e);
        }}
        onTouchEnd={async (e) => {
          await TimeLineByTouchEnd(e);
          await TimeLineByMouseUp(e);
        }}
        onMouseUp={async (e) => {
          await TimeLineByMouseUp(e);
        }}
      >
        <li></li>
        {newsList &&
          newsList
            .filter((n) => n.newType === type && n.languageResources.length > 0)
            .map((item, index) => (
              <li
                key={index}
                style={{
                  left: "0%",
                }}
              >
                <Link
                  href={`/haberler/${`${
                    item.languageResources.find(
                      (x) => x.valueType === LanguageValueType.TITLE
                    )?.slug
                  }`}`}
                  aria-label={`/haberler/${`${
                    item.languageResources.find(
                      (x) => x.valueType === LanguageValueType.TITLE
                    )?.slug
                  }`}`}
                >
                  <a
                    aria-label={
                      item.languageResources.find(
                        (x) => x.valueType === LanguageValueType.TITLE
                      )?.value
                    }
                  >
                    <div className={styles.card}>
                      <div className={styles.image}>
                        <Image
                          src={
                            (item.generalFiles.filter(
                              (x) =>
                                x.fileType == FileType.Thumbnail &&
                                x.languageId ==
                                  Language.LanguageId[lang.toUpperCase()]
                            )[0] &&
                              `${CDN_URL}${
                                item.generalFiles.filter(
                                  (x) =>
                                    x.fileType == FileType.Thumbnail &&
                                    x.languageId ==
                                      Language.LanguageId[lang.toUpperCase()]
                                )[0].uid
                              }*${
                                item.generalFiles.filter(
                                  (x) =>
                                    x.fileType == FileType.Thumbnail &&
                                    x.languageId ==
                                      Language.LanguageId[lang.toUpperCase()]
                                )[0].name
                              }`) ||
                            NoImage
                          }
                          alt=""
                          title=""
                          layout="fill"
                        />
                      </div>
                    </div>
                  </a>
                </Link>
                <div className={styles.datetime}>
                  <div className={styles.arrowone}></div>
                  <div className={styles.arrowtwo}></div>
                  <div className={styles.circle}></div>
                  <div className={styles.date}>
                    <span className={styles.number}>
                      {ConvertToDateByType(DateType.DAY, item.publishDate)}
                    </span>
                    <span className={styles.month}>
                      {t(ConvertToDateByType(DateType.MONTH, item.publishDate))}
                    </span>
                    <span className={styles.year}>
                      {ConvertToDateByType(DateType.YEAR, item.publishDate)}
                    </span>
                  </div>
                </div>
                <div className={styles.title}>
                  <Link
                    href={`/haberler/${`${
                      item.languageResources.find(
                        (x) => x.valueType === LanguageValueType.TITLE
                      )?.slug
                    }`}`}
                    aria-label={`/haberler/${`${
                      item.languageResources.find(
                        (x) => x.valueType === LanguageValueType.TITLE
                      )?.slug
                    }`}`}
                  >
                    <a>
                      <p>{`${
                        item.languageResources.find(
                          (x) => x.valueType === LanguageValueType.TITLE
                        )?.value
                      }`}</p>
                    </a>
                  </Link>
                </div>
              </li>
            ))}
      </ul>
    );

    return getNewsItems;
  };

  return (
    <section className={styles.section}>
      <div className={styles.filter}></div>
      {/* Start Title */}
      <h1 className={styles.title}>
        {t(LanguageUid.NEWS_TITLE)}

        <Link href={"/haberler"}>
          <a aria-label={t(LanguageUid.NEWS_GET_ALL_BUTTON)}>
            <span>{t(LanguageUid.NEWS_GET_ALL_BUTTON)}</span>
          </a>
        </Link>
      </h1>
      {/* End Title */}

      {/* Start News Buttons for Filtered*/}
      <div className={`btn-group ${styles.btnGroup}`}>
        <button
          style={{
            backgroundColor:
              newType === NewType.PTTNewsWorthy.Value
                ? "var(--pttYellowColor)"
                : null,
          }}
          onClick={() => onChangeNewByType(NewType.PTTNewsWorthy.Value)}
          aria-label={t(LanguageUid.NEWS_PTT_WORTHY_BUTTON)}
        >
          {t(LanguageUid.NEWS_PTT_WORTHY_BUTTON)}
        </button>

        <button
          style={{
            backgroundColor:
              newType === NewType.News.Value ? "var(--pttYellowColor)" : "#eee",
          }}
          onClick={() => onChangeNewByType(NewType.News.Value)}
          aria-label={t(LanguageUid.NEWS_NEWS_BUTTON)}
        >
          {t(LanguageUid.NEWS_NEWS_BUTTON)}
        </button>

        <button
          style={{
            backgroundColor:
              newType === NewType.UsInThePress.Value
                ? "var(--pttYellowColor)"
                : "#eee",
          }}
          onClick={() => onChangeNewByType(NewType.UsInThePress.Value)}
          aria-label={t(LanguageUid.NEWS_US_IN_THE_PRESS_BUTTON)}
        >
          {t(LanguageUid.NEWS_US_IN_THE_PRESS_BUTTON)}
        </button>
      </div>
      {/* End News Buttons for Filtered*/}

      {/* Start List */}
      {newsList.filter((x) => x.languageResources.length > 0) &&
        [
          NewType.PTTNewsWorthy.Value,
          NewType.News.Value,
          NewType.UsInThePress.Value,
        ].map((type) => GetNewsByTop(type))}
      {/* End List */}

      {newsList.every((x) => x.languageResources.length === 0) && (
        <NewsNoData></NewsNoData>
      )}

      {/* Start Timeline Button */}

      {newsList.filter((x) => x.languageResources.length !== 0).length > 0 && (
        <ul
          id="buttons"
          ref={_buttons}
          className={styles.buttons}
          aria-live="polite"
        >
          <li
            onClick={() => {
              TimeLineByNextAndPrev(TimelineButton.NEXT);
            }}
            aria-label={t(LanguageUid.TIMELINE_NEXT_BUTTON)}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </li>
          <li
            className={
              leftPercent >= 0 ? styles.timeLinePrevButtonPassive : null
            }
            onClick={() => {
              leftPercent < 0
                ? TimeLineByNextAndPrev(TimelineButton.PREV)
                : null;
            }}
            aria-label={t(LanguageUid.TIMELINE_PREV_BUTTON)}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </li>
          {/* )} */}
        </ul>
      )}

      {/* End Timeline Button */}
    </section>
  );
};

export default News;
